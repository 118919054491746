<template>
  <v-container>
    
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto px-3 py-2"
        outlined
      >
       
          <v-row>
            <v-col>
              <v-btn
                color="warning"
                class="mr-4"
                @click="goBack()"
                small
              >
                <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
              </v-btn>

              <v-btn
                color="error"
                class="mr-4"
                @click="resetForm"
                v-if="!isEdit"
                small
              >
                <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
              </v-btn>
        
            </v-col>
            <v-col>
              <span class="subtitle-2" v-if="dataListLetterType">FORM {{dataListLetterType.type_name}}</span>
            </v-col>
            <v-col class="text-right">

              <v-btn v-if="!isEdit" :disabled="!valid" 
                color="primary"
                class="mr-4"
                @click="saveAddForm()"
              >
                <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
              </v-btn>
              <v-btn v-else :disabled="!valid" 
                color="primary"
                class="mr-4"
                @click="saveEditForm()"
              >
                <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
              </v-btn>
        
            </v-col>
          </v-row>
          <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2">
            <span>Isi Nomor Surat sesuai dengan yang ada pada Lembar Surat.</span><br>
            <span>Silakan Isi data-data pada input dibawah ini:</span>
          </v-alert>
            <v-row>
              <v-col
              cols="4"
                md="4"
              >

                <v-autocomplete
                  v-model="postForm.thang"
                  :items="dataThang"
                  label="Tahun Anggaran"
                  :rules="[rules.required]"
                  :readonly="true"
                  outlined
                ></v-autocomplete>
          
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-dialog
                    ref="SelRefTglSurat"
                    v-model="selTglSurat"
                    :return-value.sync="postForm.tglSurat"
                    persistent
                    width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="postForm.tglSurat"
                      label="Tgl Surat"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                      outlined
                    ></v-text-field>
                  </template>
                    <v-date-picker 
                    v-model="postForm.tglSurat"
                    >
                    <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="selTglSurat = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.SelRefTglSurat.save(postForm.tglSurat)">OK</v-btn>
                    </v-date-picker>
                    
                </v-dialog>
                
              </v-col>
            </v-row>
            <v-row>
              

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="postForm.noSurat"
                  :rules="[rules.required]"
                  label="No. Surat"
                  outlined
                ></v-text-field>  
              </v-col>

              <v-col
              cols="3"
                md="3"
              >

                <v-autocomplete
                  v-model="postForm.eosifatsurat"
                  :items="dataListSifatSurat"
                  label="Sifat Surat"
                  item-text="sifat"
                  item-value="id"
                  :rules="[rules.required]"
                  outlined
                ></v-autocomplete>
          
              </v-col>

              <v-col
              cols="3"
                md="3"
              >

                <v-autocomplete
                  v-model="postForm.eosecuritytype"
                  :items="dataListSecurityType"
                  label="Keamanan Surat"
                  item-text="security_type"
                  item-value="id"
                  :rules="[rules.required]"
                  outlined
                ></v-autocomplete>
          
              </v-col>

              

              <v-col
                cols="12"
                md="6"
              >
                  <v-textarea
                    v-model="postForm.suratDari"
                    :rules="[rules.required]"
                    label="Dari"
                    outlined
                    auto-grow
                    rows="3"
                  ></v-textarea>  
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
            
                  <v-textarea
                    v-model="postForm.tujuanSurat"
                    :rules="[rules.required]"
                    label="Tujuan"
                    outlined
                    auto-grow
                    rows="3"
                  ></v-textarea>  
              
              </v-col>

              

              <v-col
                cols="12"
                md="12"
              >
              
                  <v-textarea
                    v-model="postForm.perihalSurat"
                    counter
                    :rules="[rules.required, rules.maxlength]"
                    label="Perihal"
                    outlined
                    auto-grow
                    rows="3"
                  ></v-textarea>  
              
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-file-input
                  v-model="attachment.file"
                  placeholder="Upload Scan Surat (PDF)"
                  label="File input"
                  show-size
                  single
                  prepend-icon="mdi-paperclip"
                  accept=".pdf"
                  outlined
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      small
                      label
                      color="primary"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <p class="outline">File Uploaded:</p>
                <p v-if="postForm.scanSurat">
                  <a target="_blank" :href="postForm.scanSurat.linkfile">{{ postForm.scanSurat.name }}</a>
                </p>
                <p v-else>
                  -
                </p>
              </v-col>

            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="warning"
                  class="mr-4"
                  @click="goBack()"
                  small
                >
                  <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
                </v-btn>

                <v-btn
                  color="error"
                  class="mr-4"
                  @click="resetForm"
                  v-if="!isEdit"
                  small
                >
                  <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
                </v-btn>
          
              </v-col>
              <v-col class="text-right">

                <v-btn v-if="!isEdit" :disabled="!valid" 
                  color="primary"
                  class="mr-4"
                  @click="saveAddForm()"
                >
                  <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
                </v-btn>
                <v-btn v-else :disabled="!valid" 
                  color="primary"
                  class="mr-4"
                  @click="saveEditForm()"
                >
                  <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
                </v-btn>
          
              </v-col>
            </v-row>

        </v-card>
      </v-form>
      <v-snackbar
          v-model="toast.show"
          :timeout="toast.timeout"
        >
          {{ toast.text }}
          <v-btn
            :color="toast.color"
            text
            @click="toast.show = false"
          >
            Close
          </v-btn>
        </v-snackbar>

        <v-dialog v-model="isLoading" fullscreen>
            <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
                <v-layout justify-center align-center>
                  <v-card
                    width="70vW"
                    class="pa-md-2 mx-lg-auto"
                    outlined
                  >
                    <v-list-item three-line centered>
                      <v-list-item-content>
                        <div class="overline mb-4">Processing</div>
                        <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-progress-linear
                            indeterminate
                            color="yellow darken-2"
                          ></v-progress-linear>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { insertDataSurat, getDataSuratByLetterGUID, updateDataSurat, deleteFileScanSurat } from '@/api/datasurat'
import { getListSifatSuratActive } from '@/api/sifatsurat'
import { getListSecurityTypeActive } from '@/api/securitytypes'
import { getLetterTypesByID } from '@/api/lettertypes'

import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'
import shortguid from 'short-uuid'


const defaultForm = {
  noSurat: '',
  tglSurat: '',
  tujuanSurat: '',
  perihalSurat: '',
  suratDari: '',
  thang: '',
  eosecuritytype: 1,
  eosifatsurat:1,
  eolettertype: 0,
  statusSurat: 11,
  letterGUID: '',
  caseGUID: '',
  deleted: 0,
  hruserpositions_createdBy: 0,
  hruserpositions_editedBy: 0,
  hrorganizations: 0
}

export default {
  name: 'FormSurat1',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      cGUID:'',
      lGUID: '',
      kdsurat:'',
      dataThang:[],
      dataListSecurityType: [],
      dataListSifatSurat: [],
      dataListLetterType: null,
      valid: false,
      selTglSurat: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 300 || 'Max 300 characters',
      },
      uploadPercentage: 0,
      isupLoading: false,
      fileUpload: null,
      attachment: {
        name: '',
        file: null,
        url: ''
      },
      isLoading: false,
      dialogConfirm: false,
      dataListOrganization: [],
      dataUser: [],
      currrole: []
    }
  },
  created () {
    const vm = this
    // var now = moment().format('YYYY-MM-DD')

    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)

    vm.dataUser = secureStorage.getItem('user')
    if (vm.dataUser !== null) {
      // console.log(vm.dataUser)
      if(vm.dataUser.role !== null) {
        if(vm.dataUser.role.id === 1) {
          vm.isAdmin = true
        }
      }
    }

    this.cGUID = this.$route.params && this.$route.params.cuid
    this.kdsurat = this.$route.params && this.$route.params.kdsurat
    // console.log('kdsurat',this.kdsurat)

    if (this.isEdit) {
      this.lGUID = this.$route.params && this.$route.params.lguid
      this.fetchData(this.lGUID)
    }
    else {
      this.postForm = Object.assign({})
    }

    this.createYears()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // var now = moment().format('YYYY-MM-DD')

    vm.postForm.thang = moment().format('YYYY')
    vm.postForm.tglSurat = moment().format('YYYY-MM-DD')

    vm.getOrgData()
    vm.getSifatSurat()
    vm.getKeamananSurat()
    vm.getLetterTypes()
  },
  computed: {

  },
  methods: {
    createYears(){
      let startDate = moment('2019-01-01').startOf('year').format('YYYY')
      const endDate = moment(new Date()).startOf('year').format('YYYY')

      const iYears = []
      while (startDate <= endDate) {
        var tmp = { text: startDate, value: startDate }
        iYears.push(tmp)
        startDate = moment(startDate).add(1, 'years').format('YYYY')
      }
      this.dataThang = iYears
    },
    goBack () {
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    getSifatSurat(){
      const vm = this
      vm.isLoading = true
      getListSifatSuratActive().then(response => {
        var data = response.data
        vm.dataListSifatSurat = data
        vm.isLoading = false
        vm.postForm.eosifatsurat = 1
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    getKeamananSurat(){
      const vm = this
      vm.isLoading = true
      getListSecurityTypeActive().then(response => {
        var data = response.data
        vm.dataListSecurityType = data
        vm.postForm.eosecuritytype = 1
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    getLetterTypes(){
      const vm = this
      vm.isLoading = true
      getLetterTypesByID(vm.kdsurat).then(response => {
        var data = response.data
        vm.dataListLetterType = data
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    getOrgData(){
      const self = this
      self.dataListOrganization = []
      var rData = secureStorage.getItem('orgall')
      if(rData != null){
        var array = []
        rData.forEach(element => {
          if(element.level == 'Main_PD' || element.level == 'Main_VPD') element.level = ''
          var tmp = { value: element.id, text: element.level + ' ' + element.structure_name + ' ('+ element.unit_code + ')' }
          array.push(tmp)
        })
        self.dataListOrganization = array
      }
    },
    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        delete vm.postForm.id
        vm.postForm.hruserpositions_createdBy = vm.currrole.id
        vm.postForm.hruserpositions_editedBy = vm.currrole.id
        vm.postForm.caseGUID = vm.cGUID
        vm.postForm.eolettertype = vm.kdsurat
        vm.postForm.statusSurat = 11

        if(vm.postForm.tglSurat){
          vm.postForm.tglSurat = moment(vm.postForm.tglSurat).format('YYYY-MM-DD')
        }

        vm.postForm.userCreator = vm.dataUser.id
        vm.postForm.userEditor = vm.dataUser.id
        if(vm.currrole){
          if(vm.currrole.hrorganization){
            vm.postForm.hrorganizations = vm.currrole.hrorganization.id
          }
        }
        vm.postForm.deleted = 0

        var translator = shortguid()
        vm.postForm.letterGUID = translator.new()
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        vm.postForm.hruserpositions_editedBy = vm.currrole.id
        vm.postForm.userEditor = vm.dataUser.id
        // vm.postForm.caseGUID = vm.cGUID
        delete vm.postForm.caseGUID
        delete vm.postForm.letterGUID
        //----
        vm.postForm.eolettertype = vm.kdsurat

       if(vm.postForm.tglSurat){
            vm.postForm.tglSurat = moment(vm.postForm.tglSurat).format('YYYY-MM-DD')
          }
        
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    
    fetchData (lguid) {
      const vm = this
      vm.isLoading = true
      getDataSuratByLetterGUID(lguid).then(response => {
        var data = response.data
        if(data.length > 0){
          var arrData = data[0]
          // console.log(vm.postForm )
          
          vm.postForm.id = arrData.id
          vm.postForm.noSurat = arrData.noSurat
          vm.postForm.tujuanSurat = arrData.tujuanSurat
          vm.postForm.perihalSurat = arrData.perihalSurat
          vm.postForm.suratDari = arrData.suratDari
          if(arrData.thang){
            vm.postForm.thang = data[0].thang.toString()
          }

          if(arrData.eosecuritytype){
            vm.postForm.eosecuritytype = arrData.eosecuritytype.id
          }
          if(arrData.eosifatsurat){
            vm.postForm.eosifatsurat = arrData.eosifatsurat.id
          }
          if(arrData.eolettertype){
            vm.postForm.eolettertype = arrData.eolettertype.id
          }
          if(arrData.statusSurat){
            vm.postForm.statusSurat = arrData.statusSurat.id
          }

          vm.postForm.letterGUID = arrData.letterGUID
          vm.postForm.caseGUID = arrData.caseGUID
          vm.postForm.deleted = arrData.deleted
          vm.postForm.isNumGenerated = arrData.isNumGenerated
          if(arrData.scanSurat){
            let tmp = {linkfile: _GLOBAL.URLBACKEND + arrData.scanSurat.url}
            let obj = arrData.scanSurat
            vm.postForm.scanSurat = Object.assign(tmp, obj);
          }

          if(arrData.tglSurat){
            vm.postForm.tglSurat = moment(arrData.tglSurat).format('YYYY-MM-DD')
          }

          if(arrData.hruserpositions_penandatangan){
            vm.penandatangan_org = arrData.hruserpositions_penandatangan.hrorganizations
            vm.vPenandatangan = arrData.hruserpositions_penandatangan.id
            vm.cekStatusEsign()
          }
          
          if(arrData.hruserpositions_createdBy){
            vm.postForm.hruserpositions_createdBy = arrData.hruserpositions_createdBy.id
          }
          if(arrData.hruserpositions_editedBy){
            vm.postForm.hruserpositions_editedBy = arrData.hruserpositions_editedBy.id
          }
          if(arrData.userCreator){
            vm.postForm.userCreator = arrData.userCreator.id
          }
          if(arrData.userEditor){
            vm.postForm.userEditor = arrData.userEditor.id
          }

          if(arrData.hrorganizations){
            vm.postForm.hrorganizations = arrData.hrorganizations.id
          }
          // console.log(vm.postForm)
        }
        
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false

      // console.log('insert:',vm.postForm)
      vm.isLoading = true
      insertDataSurat(vm.postForm).then(response => {
        // console.log('Data', response.data)
        
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
        }
        if (vm.attachment.file !== null) {
          vm.uploadAttachment(response.data, true)
        }else{
          vm.$router.go(-2)
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false

      var idb = vm.postForm.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)
      updateDataSurat(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)

        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Data BERHASIL.', timeout: 2000
        }
        if (vm.attachment.file !== null) {
          vm.uploadAttachment(response.data, false)
        }else{
          vm.goBack()
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    beforeFileUpload (file) {
      /*
      var isFormatOK = false
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        isFormatOK = true
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isFormatOK) {
        this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!')
      }
      return isFormatOK && isLt2M
      */
      return true
    },
    filesChange (uploadfile) {
      this.attachment.file = uploadfile[0]
      // console.log(this.attachment.file)
    },
    addAttachment (file, fileList) {
      this.attachment.file = file
      // console.log(this.attachment.file)
    },
    uploadAttachment (postForm, isInsert) {
      const vm = this
      // console.log('uploadAttachment',vm.attachment)

      if (vm.attachment.file && postForm) {
        var file = vm.attachment.file
        if (vm.beforeFileUpload(file)) {
          if (postForm.id !== 0) {
            if(postForm.scanSurat){
              deleteFileScanSurat(postForm.scanSurat.id).then(respdelFile => {
                vm.uploadFileAttachment(postForm.id, file, isInsert)
              })
              .catch(err => {
                vm.uploadFileAttachment(postForm.id, file, isInsert)
              })
            }else{
              vm.uploadFileAttachment(postForm.id, file, isInsert)
            }
          }
          else {
            vm.goBack()
          }
        }
      }
    },
    uploadFileAttachment (refID, file, isInsert) {
      const vm = this

      var token = secureStorage.getItem('jwt')
      if (!token) return null

      const bodyFormData = new FormData()
      bodyFormData.append('refId', refID)
      bodyFormData.append('field', 'scanSurat')
      bodyFormData.append('ref', 'Datasurat')
      bodyFormData.append('path', 'Datasurat/scanSurat')
      // Add each file upload to the form data
      bodyFormData.append('files', file, file.name)
      // console.log('uploadFileAttachment', bodyFormData)
      var uri = _GLOBAL.URLBACKEND + '/upload'
      
      vm.isupLoading = true
      vm.uploadPercentage = 0
      axios.post(uri,
        bodyFormData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
          },
          onUploadProgress: function( progressEvent ) {
            vm.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(vm)
        }
      ).then(function(response){
        // console.log('SUCCESS!!');
        // console.log(response.data)

        vm.isupLoading = false
        vm.toast = {
          show: true, color:'green', text: 'File Scan Surat telah BERHASIL tersimpan.', timeout: 2000
        }

        if(isInsert){
          vm.$router.go(-2)
        }else{
          vm.goBack()
        }
      })
      .catch(function(err){
        // console.log('FAILURE!!');
        // console.log(err)
          vm.isupLoading = false
          vm.toast = {
            show: true, color:'red', text: 'File Scan Surat GAGAL tersimpan, silakan dicoba kembali.', timeout: 2000
          }
      });
    }
    
  }
}

</script>
